
import Header from './components/Header/Header';

function App() {
  return (
    <div>
      <div className="App">
        <Header />
      </div>
    </div>
  );
}

export default App;
